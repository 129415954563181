import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Row, Col, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledDivWrapper = styled.div`
  .h3 {
    color: ${props => props.theme.primeBluePalette.original};
  }

  .insight-date {
    font-size: 90%;
    opacity: 80%;
  }

  h4 {
    margin-bottom: 6px;
    :hover {
      opacity: 0.925;
    }
  }

  .insight-card {
    height: 100%;
  }

  .content,
  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .title {
    color: ${props => props.theme.primeBluePalette.original};
    font-size: 20px;
  }

  .content {
    color: ${props => props.theme.blackPalette.original};
    line-height: 1.1516;
  }

  .highlight.insight-card {
    background-color: ${props => props.theme.primeBluePalette.original};
    color: ${props => props.theme.whitePalette.original};
    .content {
      color: ${props => props.theme.whitePalette.original};
    }
    h4 {
      color: ${props => props.theme.primeYellowPalette.original};
    }
    .cta-link > a {
      color: ${props => props.theme.primeYellowPalette.original};
    }
  }

  .cta-link {
    display: flex;
    justify-content: flex-end;
    margin-top: 14px;
  }

  .cta-link > a {
    color: ${props => props.theme.primeBluePalette.original};
    :hover {
      opacity: 0.8;
    }
  }
`;

function IndustryInsightsComponent({ blogs, title }) {
  return (
    <StyledDivWrapper>
      <div>
        <h3>{title ? title : 'INDUSTRY INSIGHTS'}</h3>
      </div>
      <div>
        <Row type="flex" gutter={[10, 10]}>
          {blogs.map((blog, index) => (
            <Col
              key={blog.strapiId}
              xs={24}
              sm={24}
              md={index === 0 ? 24 : 12}
              lg={index === 0 ? 24 : 12}
              xl={index === 0 ? 24 : 12}
              xxl={index === 0 ? 24 : 12}
            >
              <Card
                className={
                  index === 0 ? 'highlight insight-card' : 'insight-card'
                }
              >
                <Link to={`/insights/articles/${blog.url_link}`}>
                  <h4 className="title">{blog.title}</h4>
                </Link>
                <p className="insight-date">
                  {blog.published_date} • {blog.author}
                </p>
                <div className="content">{blog.description}</div>
                <div className="cta-link">
                  <Link to={`/insights/articles/${blog.url_link}`}>
                    READ MORE <FontAwesomeIcon icon="angle-right" />
                  </Link>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </StyledDivWrapper>
  );
}

export default IndustryInsightsComponent;
