import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { useStaticQuery, graphql } from 'gatsby';

const StyledDivWrapper = styled.div`
  .h3 {
    color: ${props => props.theme.primeBluePalette.original};
  }

  .card-wrapper {
    display: flex;
    background-image: ${() => `url('../../images/home/events-bg.jpg')`};
    color: white;
    padding: 10px;
    margin-bottom: 6px;
    position: relative;

    > div,
    > h4 {
      position: relative;
      z-index: 1;
    }

    :last-child {
      margin-bottom: 0px;
    }
    ::after {
      background-color: ${props => `${props.theme.blackPalette.original}CC`};
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    .card-date {
      background-color: ${props =>
        `${props.theme.primeYellowPalette.original}`};
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      line-height: 1;
      > h3,
      h4 {
        margin-bottom: 0px;
        color: ${props => `${props.theme.primeBluePalette.original}`};
      }
    }

    .card-content {
      padding: 0px 10px;
    }

    .card-title {
    }

    .card-footer {
      display: flex;
      justify-content: space-between;
      font-size: 75%;
      a:hover,
      a {
        color: white;
        text-decoration: underline;
      }
      a:hover {
        opacity: 0.8;
      }
    }
  }
`;

function UpComingEventsComponent() {
  const data = useStaticQuery(graphql`
    {
      allStrapiUpcomingEvents(
        sort: { fields: event_date, order: DESC }
        limit: 2
      ) {
        nodes {
          platform_text
          platform_logo_path
          strapiId
          title
          url_path
          event_date(formatString: "MMMM DD, YYYY")
          cta_text
        }
      }
    }
  `);

  const allStrapiUpcomingEvents = data.allStrapiUpcomingEvents.nodes;

  return (
    <StyledDivWrapper>
      <div>
        <div>
          <h3>EVENTS</h3>
        </div>
        <div>
          {(allStrapiUpcomingEvents.length === 1
            ? [...allStrapiUpcomingEvents, null]
            : allStrapiUpcomingEvents.length === 2
            ? [...allStrapiUpcomingEvents]
            : allStrapiUpcomingEvents
          ).map(event =>
            event !== null ? (
              <div className="card-wrapper" key={event.strapiId}>
                <div className="card-date">
                  <h4 style={{ textTransform: 'uppercase' }}>
                    {moment(event.event_date).format('MMM')}
                  </h4>
                  <h3>{moment(event.event_date).format('DD')}</h3>
                </div>
                <div className="card-content">
                  <h4 className="card-title">{event.title}</h4>
                  <div className="card-footer">
                    <div>{/* {event.platformText} */}</div>
                    {event.url_path ? (
                      <OutboundLink
                        href={event.url_path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {event.cta_text}
                      </OutboundLink>
                    ) : (
                      <div className="card-subtitle">{event.cta_text}</div>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="card-wrapper"
                key={0}
                style={{
                  justifyContent: 'center',
                  alignContent: 'center',
                  alignItems: 'center',
                  minHeight: 65,
                }}
              >
                <h4 style={{ margin: 0 }}>STAY TUNED FOR MORE EVENTS</h4>
              </div>
            )
          )}
        </div>
      </div>
    </StyledDivWrapper>
  );
}

export default UpComingEventsComponent;
