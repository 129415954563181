import React from 'react';
import styled from 'styled-components';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { useStaticQuery, graphql } from 'gatsby';

const StyledDivWrapper = styled.div`
  padding-top: 20px;
  p {
    color: ${props => props.theme.whitePalette.original};
  }

  .thumbnail {
    h4 {
      color: ${props => props.theme.primeYellowPalette.original};
      margin-bottom: 6px;
    }
    p {
      margin-bottom: 0px;
      font-size: 90%;
    }
    padding: 20px;
    min-height: 250px;
    height: 100%;
    background: ${props => `url('${props.thumbnail}')`}, #cccccc;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;

    > div {
      z-index: 1;
    }
    ::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        to bottom,
        rgba(50, 50, 50, 0) 0%,
        rgba(50, 50, 50, 0.88) 83%,
        rgba(50, 50, 50, 0.88) 100%
      );
      z-index: 0;
    }
  }
`;

function FeaturedHighlightComponent() {
  const data = useStaticQuery(graphql`
    {
      strapiMediaAppearance(featured: { eq: true }) {
        title
        strapiId
        thumb
        channel
        published_date(formatString: "MMMM DD, YYYY")
        featured
      }
    }
  `);

  return (
    <StyledDivWrapper thumbnail={data.strapiMediaAppearance.thumb}>
      <div>
        <div>
          <h3>FEATURED HIGHLIGHT</h3>
        </div>
        <div>
          <OutboundLink href={data.strapiMediaAppearance.url_link || ''}>
            <div className="thumbnail">
              <div>
                <h4>{data.strapiMediaAppearance.title}</h4>
                <p>{data.strapiMediaAppearance.published_date}</p>
              </div>
            </div>
          </OutboundLink>
        </div>
      </div>
    </StyledDivWrapper>
  );
}

export default FeaturedHighlightComponent;
